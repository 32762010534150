// @ts-nocheck
import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { getCookie } from 'typescript-cookie'

import BalanceCard from '../../components/balance-card/BalanceCard'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { rounding_format } from '../../libs/utils/utils'

import balanceIcon from '../../assets/images/balance.svg'
import invoiceIcon from '../../assets/images/invoice.svg'
import withdrawalIcon from '../../assets/images/withdrawal.svg'
import courseIcon from '../../assets/images/course.svg'
import { useGetBalanceQuery } from '../../store/services/balance-service'
import { IBalance } from '../../types/balance'
import { useGetCourseMMutation } from '../../store/services/course-service'
import { useGetCashflowQuery } from '../../store/services/cashflow-service'
import { useLazyRefreshQuery } from '../../store/services/base-service'

const Wrapper = styled.div`
	/* display: flex;
    justify-content: space-between; */
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 24px;
	color: #1b2341;

	@media screen and (max-width: 1110px) {
		grid-template-columns: repeat(2, 1fr) !important;
		gap: 14px;
	}

	@media screen and (max-width: 410px) {
		grid-template-columns: repeat(2, 1fr) !important;
    	gap: 5px;
	}
`

const BalanceCards: React.FC<{}> = () => {
	const location = useLocation()
	const { token } = useAppSelector(state => state.currency)

	const [refresh] = useLazyRefreshQuery()

	const { data: balanceData, isError: isErrorBalanceData } = useGetBalanceQuery(getCookie('is_fiat') === 'true' && location.pathname === '/withdraw' ? 'USDTTRC' : token?.token || 'CARDRUBP2P', { pollingInterval: 15000, refetchOnMountOrArgChange: true })
	const [getCourse, { data: course, isError: isErrorCourse }] = useGetCourseMMutation()
	const { data: cash, isError: isErrorCash } = useGetCashflowQuery(getCookie('is_fiat') === 'true' && location.pathname === '/withdraw' ? 'USDTTRC' : token?.token || 'CARDRUBP2P', { pollingInterval: 15000 })
	const defaultBalanceData = [{ amount: 10000, currency: 'USDT' }]

	React.useEffect(() => {
		if (isErrorBalanceData || isErrorCourse || isErrorCash) {
			refresh(null)
		}
	}, [isErrorBalanceData, isErrorCourse, isErrorCash])

	React.useEffect(() => {
		if (getCookie('is_fiat') === 'false') {
			setTimeout(() => {
				getCourse(token?.token || 'CARDRUBP2P')
			}, 15000)
		}
	}, [])

	const balanceCard = React.useMemo(() => {
		return (
			<BalanceCard
				values={balanceData?.map(
					(balance: IBalance) => +rounding_format(balance?.amount!, 2, '.', ''),
				)}
				freeze={balanceData?.map(
					(balance: IBalance) => +rounding_format(balance?.freeze_amount!, 2, '.', ''),
				)}

				// profitBalance='24'
				label="balance"
				bg="#DA932C"
				arr={balanceData || defaultBalanceData}
				icon={balanceIcon}
			/>
		)
	}, [balanceData])

	const [inBalance, outBalance] = React.useMemo(() => {
		return [
			{
				currency: cash?.currency,
				in: cash?.in ? +rounding_format(cash?.in, 2, '.', '') : 0,
				today_in: cash?.today_in ? +rounding_format(cash?.today_in, 2, '.', '') : 0,
			},
			{
				currency: cash?.currency,
				out: cash?.out ? +rounding_format(cash?.out, 2, '.', '') : 0,
				today_out: cash?.today_out ? +rounding_format(cash?.today_out, 2, '.', '') : 0,
			},
		]
	}, [cash])

	const cashCards = React.useMemo(() => {
		return (
			<>
				<BalanceCard
					values={[inBalance.today_in]}
					profitBalance={[inBalance.today_in]}
					label="income"
					bg="#D65A4A"
					arr={[inBalance]}
					borderColor="#a42741"
					icon={invoiceIcon}
				/>
				<BalanceCard
					values={[outBalance.today_out]}
					profitBalance={[outBalance.today_out]}
					label="outcome"
					bg="#65B92F"
					arr={[outBalance]}
					borderColor="#00850b"
					icon={withdrawalIcon}
				/>
			</>
		)
	}, [inBalance, outBalance])

	const courseCard = React.useMemo(() => {
		return (
			<BalanceCard
				values={[course?.buy_rate, course?.sell_rate]?.map((courseItem: any, index: number) => {
					// const percent = index === 0 ? course.price / 100 : course.price / 100
					return +rounding_format(courseItem, 2, '.', '')
					// return rounding_format(course.price, 2, '.', '')
				})}
				// profitBalance='5%'
				label="rate"
				bg="#2198F1"
				arr={[{ currency: 'buy' }, { currency: 'sell' }]}
				icon={courseIcon}
			/>
		)
	}, [course])

	return (
		<Wrapper style={token?.type === 'P2P' && getCookie('is_fiat') === 'false' ? { gridTemplateColumns: 'repeat(4,1fr)' } : { gridTemplateColumns: 'repeat(3,1fr)' }}>
			{balanceCard}
			{cashCards}
			{token?.type === 'P2P' && getCookie('is_fiat') === 'false' ? courseCard
				: null
			}
		</Wrapper>
	)
}

export default React.memo(BalanceCards)
