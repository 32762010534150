import React from 'react'
import styled from 'styled-components'
import LoadingButton from '@mui/lab/LoadingButton'
import FilePresentIcon from '@mui/icons-material/FilePresent'
import moment from 'moment-timezone'

import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import CustomSelect, { IOption } from '../../components/custom-select/CustomSelect'
import { useCurrencies } from '../../hooks/use-currencies'
import Button from '../../components/button/button'
import InputWithLabel from '../../components/input-with-label/input-with-label'
import { useFetchTransactionsCsvQuery } from '../../store/services/transactions-service'
import Popup from '../../components/popup/popup'
import Download from '../../pages/downloads/download'

const CsvWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;
	width: 100%;
`

const CSVButton = () => {
    const currencies = useCurrencies()
    const [tokensArray, setTokensArray] = React.useState<IOption[]>([])
    const [selectedToken, setSelectedToken] = React.useState<IOption | undefined>(undefined)
    const [selectedStatus, setSelectedStatus] = React.useState<IOption>({name: 'ALL', label: 'all' })
    const [selectedType, setSelectedType] = React.useState<IOption>({name: 'ALL', label: 'all' })
    const [selectedTimezone, setSelectedTimezone] = React.useState<IOption>({name: 'GMT-0', label: 'GMT+0'})
    const [isCsvPopupVisible, setIsCsvPopupVisible] = React.useState(false)
    const [downloadPageVivible, setDownloadPageVivible] = React.useState(false)

    const [bodyParams, setBodyParams] = React.useState<null | {}>(null)

    const {t} = useTranslation()
    const fillTime = () => toast.error(t('components.errorTime'))

    const availableFormats = [{name: 'csv'}, {name: 'xls'}]
    const [format, setFormat] = React.useState<{ name: string }>(
        availableFormats?.[0],
      )
      const selectFormat = (el: any) => {
        setFormat(el)
      }
    const [inputsScv, setInputsScv] = React.useState<any>({
        'dateFrom': new Date(),
        'dateTo': new Date(),
    })

    const { isLoading: isLoadingCsv, refetch } = useFetchTransactionsCsvQuery(bodyParams);
    const statuses = [
        {
            name: 'all',
            label: 'ALL',
        },
        {
            name: 'success',
            label: 'SUCCESS',
        },
        {
            name: 'error',
            label: 'ERROR',
        },
    ]

    const types = [
        {
            name: 'all',
            label: 'ALL',
        },
        {
            name: 'in',
            label: 'IN',
        },
        {
            name: 'out',
            label: 'OUT',
        },
    ]

    const timezones = [
        {
            name: 'GMT-1',
            label: 'GMT+1',
        },
        {
            name: 'GMT-2',
            label: 'GMT+2',
        },
        {
            name: 'GMT-3',
            label: 'GMT+3',
        },
        {
            name: 'GMT-4',
            label: 'GMT+4',
        },
        {
            name: 'GMT-5',
            label: 'GMT+5',
        },
        {
            name: 'GMT-6',
            label: 'GMT+6',
        },
        {
            name: 'GMT-7',
            label: 'GMT+7',
        },
        {
            name: 'GMT-8',
            label: 'GMT+8',
        },
        {
            name: 'GMT-9',
            label: 'GMT+9',
        },
        {
            name: 'GMT-10',
            label: 'GMT+10',
        },
        {
            name: 'GMT-11',
            label: 'GMT+11',
        },
        {
            name: 'GMT-12',
            label: 'GMT+12',
        },
        {
            name: 'GMT-0',
            label: 'GMT+0',
        },
        {
            name: 'GMT+1',
            label: 'GMT-1',
        },
        {
            name: 'GMT+2',
            label: 'GMT-2',
        },
        {
            name: 'GMT+3',
            label: 'GMT-3',
        },
        {
            name: 'GMT+4',
            label: 'GMT-4',
        },
        {
            name: 'GMT+5',
            label: 'GMT-5',
        },
        {
            name: 'GMT+6',
            label: 'GMT-6',
        },
        {
            name: 'GMT+7',
            label: 'GMT-7',
        },
        {
            name: 'GMT+8',
            label: 'GMT-8',
        },
        {
            name: 'GMT+9',
            label: 'GMT-9',
        },
        {
            name: 'GMT+10',
            label: 'GMT-10',
        },
        {
            name: 'GMT+11',
            label: 'GMT-11',
        },
        {
            name: 'GMT+12',
            label: 'GMT-12',
        },
    ]

    React.useEffect(() => {
        if (currencies) {
            setTokensArray(currencies.map((item) => {
                return {
                    name: item.full_name.replace(/\s+/g, ''),
                    label: item.token,
                }
            }))
        }
        setTokensArray((prev) => [{
            name: 'all',
            label: 'ALL',
        }, ...prev])
    }, [currencies])

    React.useEffect(() => {
        setSelectedToken(tokensArray[0])
    }, [tokensArray])

    React.useEffect(() => {
        if (bodyParams) {
            refetch()
        }
      }, [bodyParams, refetch])

    function changeInputs(e: React.ChangeEvent<HTMLInputElement>, type?: string) {
        const { name, value } = e.target
        const split = value.split('-')
        const isDate = name === ('dateFrom' || 'dateTo') ? true : false
        if (isDate && Number(split[0]) > 10000) {
            const newDate = Math.floor(Number(split[0]) / 10)
            setInputsScv((prev: any) => ({
                ...prev,
                [name]: `${newDate}-${split[1]}-${split[2]}`,
            }))
        } else {
            setInputsScv((prev: any) => ({
                ...prev,
                [name]: value,
            }))
        }
    }

    return (
        <>  
            <LoadingButton
                onClick={() => setIsCsvPopupVisible(true)}
                loading={isLoadingCsv}
                variant="contained"
                sx={{
                    width: '170px',
                    height: '35px',
                    background: '#7154DC',
                    padding: '6px',
                    textTransform: 'none',
                    fontSize: '16px',
                    fontFamily: 'sans-serif',
                    borderRadius: '8px',
                    ':hover': {
                        background: '#634acf',
                    },
                    '@media (min-width: 416px) and (max-width: 565px)': {
                        fontSize: '12px',
                    },
                    '@media (max-width: 415px)': {
                        minWidth: '80px',
                        height: 'max-content',
                        fontSize: '12px',
                    },
                }}
                >
                <span>{isLoadingCsv ? `${t('components.loading')}...` : t('components.prepareDownload')}</span>
            </LoadingButton>
                <Button small={true} onClick={()=>setDownloadPageVivible(true)}><FilePresentIcon fontSize="small" /></Button>
            <Popup isVisible={isCsvPopupVisible} setIsVisible={setIsCsvPopupVisible}>
                <CsvWrapper>
                <div style={{ display: 'flex', gap: '10px', flexDirection: window.innerWidth > 530 ? 'row' : 'column' }}>
                    <CustomSelect
                        options={availableFormats}
                        width="240px"
                        height="34px"
                        label={'Format'}
                        selectHandler={selectFormat}
                        selectedOption={format}
                    />
                    {
                        isCsvPopupVisible &&
                        <CustomSelect
                            label={'chooseToken'}
                            options={tokensArray}
                            width="240px"
                            height="34px"
                            selectHandler={setSelectedToken}
                            selectedOption={selectedToken} />
                    }
                    </div>
                    <div style={{ display: 'flex', gap: '10px', flexDirection: window.innerWidth > 530 ? 'row' : 'column' }}>
                    <CustomSelect
                        options={statuses}
                        width="240px"
                        height="34px"
                        label={'status'}
                        selectHandler={setSelectedStatus}
                        selectedOption={selectedStatus}
                    />
                    <CustomSelect
                        options={types}
                        width="240px"
                        height="34px"
                        label={'type'}
                        selectHandler={setSelectedType}
                        selectedOption={selectedType}
                    />
                    </div>
                    <CustomSelect
                        options={timezones}
                        width="240px"
                        height="34px"
                        label={'timezone'}
                        selectHandler={setSelectedTimezone}
                        selectedOption={selectedTimezone}
                    />
                    <div style={{ display: 'flex', gap: '10px', flexDirection: window.innerWidth > 530 ? 'row' : 'column' }}>
                    <InputWithLabel
                        required
                        name={'dateFrom'}
                        value={inputsScv['dateFrom']}
                        label="dateFrom"
                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => changeInputs(e)}
                        type="date"
                        placeholder=""
                    />
                    <InputWithLabel
                        required
                        name={'timeFrom'}
                        value={inputsScv['timeFrom']}
                        label="timeFrom"
                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => changeInputs(e)}
                        type="time"
                        placeholder=""
                    />
                    </div>
                    <div style={{ display: 'flex', gap: '10px', flexDirection: window.innerWidth > 530 ? 'row' : 'column' }}>
                    <InputWithLabel
                        required
                        name={'dateTo'}
                        value={inputsScv['dateTo']}
                        label="dateTo"
                        type="date"
                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => changeInputs(e)}
                        placeholder=""
                    />
                    <InputWithLabel
                        required
                        name={'timeTo'}
                        value={inputsScv['timeTo']}
                        label="timeTo"
                        type="time"
                        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => changeInputs(e)}
                        placeholder=""
                    />
                    </div>
                    <Button
                        onClick={() => {
                            var { dateFrom, timeFrom, dateTo, timeTo } = inputsScv
                            if (dateFrom && timeFrom && dateTo && timeTo) {
                                const dateFromForTimeZone = inputsScv['dateFrom'].replace('/', '-') + ' ' + inputsScv['timeFrom']
                                const dateToForTimeZone = inputsScv['dateTo'].replace('/', '-') + ' ' + inputsScv['timeTo']
                                const dateFromWithTimeZone = moment.tz(dateFromForTimeZone, `Etc/${selectedTimezone.label}`).utc().format()
                                const dateToWithTimeZone = moment.tz(dateToForTimeZone, `Etc/${selectedTimezone.label}`).utc().format()
                                
                                if (dateFrom > dateTo) {
                                    fillTime()
                                } else {
                                    setBodyParams({
                                        time_from: dateFromWithTimeZone,
                                        time_to: dateToWithTimeZone,
                                        format: format?.name,
                                        token_name: selectedToken?.label,
                                        status: selectedStatus?.label,
                                        type: selectedType?.label,
                                        timezone: selectedTimezone.name,
                                    })
                                    setIsCsvPopupVisible(false)
                                }
                            } else fillTime()
                        }}
                        variant="primary"
                        >
                        {t('components.toDownLoadCSV')}
                    </Button>
                </CsvWrapper>
            </Popup>
            <Popup isVisible={downloadPageVivible} setIsVisible={setDownloadPageVivible}>
                <Download />
            </Popup>
            
        </>
    )
}

export default React.memo(CSVButton)