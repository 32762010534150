import React from 'react'
import { Route, Routes } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ToastContainer } from 'react-toastify'
import { getCookie } from 'typescript-cookie'
import 'react-toastify/dist/ReactToastify.css'

import PrivateRoute from './hocks/private-route'
import Header from './components/header/header'

import { pages } from './pages'
import { useAppSelector } from './hooks/redux'
import { AuthorizationStatus, PATH } from './libs/consts'

import NotExist from './pages/not-exist/NotExist'
import Conversions from './pages/conversions/Conversions'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
`

function App() {
  const { authorizationStatus } = useAppSelector(state => state.base)

  return (
    <>
        <Wrapper>
          {
            authorizationStatus === AuthorizationStatus.AUTH && <Header />
          }
          <Routes>
            {pages.map((el, idx) => (
              <Route
                key={idx}
                path={el.path}
                element={<PrivateRoute>{el.element}</PrivateRoute>}
              />
            ))}
            {getCookie('is_fiat') === 'true' && (
              <Route
                key="875847"
                path={PATH.conversions}
                element={<PrivateRoute><Conversions /></PrivateRoute>}
              />
            )}
            <Route path="*" element={<NotExist />} />
          </Routes>
        </Wrapper>
        <ToastContainer position="top-center" autoClose={3000} hideProgressBar={true} theme="colored" />
      </>
  )
};

export default React.memo(App)
