import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import Skeleton from '@mui/material/Skeleton'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useTranslation } from 'react-i18next'
import { getCookie } from 'typescript-cookie'

import CustomSelect, {
	IOption,
} from '../../components/custom-select/CustomSelect'
import Pagination from '../../components/pagination/pagination'
import Table from '../../components/table/Table'
import { addStatusIcon, findStatus } from '../../libs/utils/utils'
import { useFetchTransactionsQuery } from '../../store/services/transactions-service'
import { IListRequestData, ITransactionsResponseData } from '../../types/types'
import { useLazyRefreshQuery } from '../../store/services/base-service'
import BalanceTableCard from '../../components/balance-table-card/BalanceTableCard'
import CopyableText from '../../components/copyAbleText/copyAbleText'
import InputWithLabel from '../../components/input-with-label/input-with-label'
import useDebounce from '../../hooks/use-debounce'

const StyledApplicationHead = styled.div`
	display: flex;
	background: transperent;
	padding: 20px;
	gap: 16px;

	@media screen and (max-width: 1040px) {
		gap: 10px;
	}

	@media screen and (max-width: 980px) {
		gap: 5px;
	}
`

const TableWrapper = styled.div`
  	width: 100%;
  	overflow-x: auto;
  	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  	border: 1px solid #e0e0e0;
  	z-index: -1;
`

const ButtonsWrapper = styled.div`
	font-size: 17px;
	line-height: 140%;
	/* color: #2198f1; */
	display: flex;
	align-items: center;
	gap: 24px;

	@media screen and (max-width: 1040px) {
		gap: 10px;
	}

	@media screen and (max-width: 980px) {
		gap: 5px;
	}
`

const ButtonContent = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	> div {
		:first-child {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`

const StatusWrapper = styled.div<{ color: string }>`
	font-size: 14px;
	color: ${({ color }) => color};
`

const DateWrapper = styled.div<{ color?: string }>`
  font-size: 14px;
  white-space: nowrap;
  color: ${({ color }) => color || 'black'};
`

const PaginationWrapper = styled.div`
  background: white;
  padding: 0 20px;
  margin-bottom: 160px;
  width: 100%;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);

  @media screen and (max-width: 768px) {
		background: transparent;
    box-shadow: none;
	}
`

interface TransactionsProps { }

const typeName = {
	IN: 'in',
	INVOICE: 'in',
	WITHDRAW: 'out',
	OUT: 'out',
}

const typeFilterArr = [
	//todo
	{ id: 0, label: '', name: 'noType' },
	{ id: 1, label: 'IN', name: 'in' },
	{ id: 2, label: 'OUT', name: 'out' },
]

const statusOptions = [
	{ name: 'all', label: '', id: 3 },
	{ name: 'accepted', label: 'ACCEPTED', id: 0 },
	{ name: 'successful', label: 'SUCCESS', id: 1 },
	{ name: 'canceled', label: 'ERROR', id: 2 },
]

const Transactions: React.FC<TransactionsProps> = () => {
	const windowWidth = window.innerWidth
	const navigate = useNavigate()
	const {t} = useTranslation()

	const [type, setType] = React.useState<string>(typeFilterArr[0].label)
	const [status, setStatus] = React.useState<string>(statusOptions[0].label)
	const [limit, setLimit] = React.useState<number>(10)
	const [offset, setOffset] = React.useState<number>(0)
	const [page, setPage] = React.useState<number>(1)
	const [isFilterClicked, setIsFilterClicked] = React.useState(false)
	const [dateFrom, setDateFrom] = React.useState<string | undefined>(undefined)
  	const [dateTo, setDateTo] = React.useState<string | undefined>(undefined)
	  const debouncedDateFrom = useDebounce(dateFrom, 500)
	  const debouncedDateTo = useDebounce(dateTo, 500)

	const [refresh] = useLazyRefreshQuery()

	const data = React.useMemo<IListRequestData>(() => {
		return (
			{
				limit: limit,
				offset: offset,
				date_from: debouncedDateFrom ? new Date(debouncedDateFrom)?.toISOString() : undefined,
      			date_to: debouncedDateTo ? new Date(debouncedDateTo)?.toISOString() : undefined,
				token: 'USDTTRC',
				type: type ? type : undefined,
				status: status ? status : undefined,
			}
		)
	}, [type, status, limit, offset, debouncedDateFrom, debouncedDateTo])

	const defaultPageHandler = React.useCallback(() => {
		setPage(1)
		navigate('/withdraw')
	}, [])


	const selectTypeHandler = React.useCallback((option: IOption) => {
		setIsFilterClicked(true)
		if (option?.label || option?.label === '') setType(option?.label)
		defaultPageHandler()
	}, [])

	const selectStatusHandler = React.useCallback((option: IOption) => {
		setIsFilterClicked(true)
		if (option?.label || option?.label === '') setStatus(option?.label)
		defaultPageHandler()
	}, [])

	const { data: transactions, isFetching: isFetchingHistory, isLoading: isLoadingHistory, isError: isErrorHistory } = useFetchTransactionsQuery(data, { pollingInterval: 15000 })

	const handleOnChangePage = React.useCallback(
		(newPage: number) => {
			const newOffset = (newPage - 1) * limit
			setOffset(newOffset)
			setPage(newPage)
			setIsFilterClicked(true)
		}, [limit])

	React.useEffect(() => {
		if (!isFetchingHistory) {
		  setIsFilterClicked(false)
		}
	  }, [isFetchingHistory, isFilterClicked])

	React.useEffect(() => {
		if (isErrorHistory) {
			refresh(null)
		}
	}, [isErrorHistory])

	return (
		<div>
			{windowWidth > 950 && (
				<StyledApplicationHead>
					<ButtonsWrapper>
						{isLoadingHistory ? (
					<div style={{ display: 'flex', marginTop: '40px', gap: window.innerWidth > 980 ? '15px' : '5px'}}>		
						<div><Skeleton variant="rounded" width={220} height={35} /></div>
						<div><Skeleton variant="rounded" width={220} height={35} /></div>
						<div><Skeleton variant="rounded" width={220} height={35} /></div>
						<div><Skeleton variant="rounded" width={220} height={35} /></div>
					</div>
				) :  (
					<>
							<ButtonContent>
								<CustomSelect
									options={typeFilterArr}
									label="type"
									width="220px"
									height="35px"
									selectHandler={selectTypeHandler}
									backgroundColor="#fff"
								/>
							</ButtonContent>
							<ButtonContent>
								<CustomSelect
									options={statusOptions}
									label="status"
									width="220px"
									height="35px"
									selectHandler={selectStatusHandler}
									backgroundColor="#fff"
								/>
							</ButtonContent>
								<InputWithLabel
									label="dateFrom"
									placeholder=""
									value={dateFrom || ''}
									onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateFrom(e.target.value.substring(0, 10))}
									type="date"
									height="35px"
									width="220px"
									border="1px solid #e0e0e0"
									boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
									boxShadowHover="0px 4px 6px rgba(0, 0, 0, 0.1)"
								/>
								<InputWithLabel
									label="dateTo"
									placeholder=""
									value={dateTo || ''}
									onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateTo(e.target.value.substring(0, 10))}
									type="date"
									height="35px"
									width="220px"
									border="1px solid #e0e0e0"
									boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
									boxShadowHover="0px 4px 6px rgba(0, 0, 0, 0.1)"
								/>
							
					</>
				)}
				    </ButtonsWrapper>
				</StyledApplicationHead>
			)}
			{windowWidth > 550 && windowWidth <= 950 && (
					<StyledApplicationHead>
						{isLoadingHistory ? (
					<div>
						<div style={{ display: 'flex', gap: '20px'}}>
							<div style={{ marginTop: '40px'}}><Skeleton variant="rounded" width={220} height={35} /></div>
							<div style={{ marginTop: '40px'}}><Skeleton variant="rounded" width={220} height={35} /></div>
						</div>
						<div style={{ display: 'flex', gap: '20px'}}>
							<div style={{ marginTop: '40px'}}><Skeleton variant="rounded" width={220} height={35} /></div>
							<div style={{ marginTop: '40px'}}><Skeleton variant="rounded" width={220} height={35} /></div>
						</div>
					</div>
				) :  (
					<div>
						<div style={{ display: 'flex', gap: '20px'}}>
							<ButtonContent>
								<CustomSelect
									options={typeFilterArr}
									label="type"
									width="220px"
									height="35px"
									selectHandler={selectTypeHandler}
									backgroundColor="#fff"
								/>
							</ButtonContent>
							<ButtonContent>
								<CustomSelect
									options={statusOptions}
									label="status"
									width="220px"
									height="35px"
									selectHandler={selectStatusHandler}
									backgroundColor="#fff"
								/>
							</ButtonContent>
							</div>
							<div style={{ display: 'flex', gap: '20px'}}>
								<InputWithLabel
									label="dateFrom"
									placeholder=""
									value={dateFrom || ''}
									onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateFrom(e.target.value.substring(0, 10))}
									type="date"
									height="34px"
									width="220px"
									border="1px solid #e0e0e0"
									boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
									boxShadowHover="0px 4px 6px rgba(0, 0, 0, 0.1)"
								/>
								<InputWithLabel
									label="dateTo"
									placeholder=""
									value={dateTo || ''}
									onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateTo(e.target.value.substring(0, 10))}
									type="date"
									height="34px"
									width="220px"
									border="1px solid #e0e0e0"
									boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
									boxShadowHover="0px 4px 6px rgba(0, 0, 0, 0.1)"
								/>
								</div>
							</div>)}
					</StyledApplicationHead>
			)}
			{windowWidth <= 550 && (
				<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '15px', flexDirection: 'column'}}>
					{isLoadingHistory ?	(
						<>
          					{[...Array(2)].map((_, index) => (
            					<div key={index} style={{ marginTop: '16px' }}>
              						<Skeleton variant="rounded" width={150} height={35} />
	            				</div>
    	      				))}
							{[...Array(2)].map((_, index) => (
            					<div key={index} style={{ marginTop: '16px' }}>
              						<Skeleton variant="rounded" width={340} height={35} />
	            				</div>
    	      				))}
        				</>
				) : ( 
			    	<>
						<CustomSelect
							options={typeFilterArr}
							label="type"
							width="150px"
							height="35px"
							backgroundColor="#fff"
							selectHandler={selectTypeHandler}
						/>
						<CustomSelect
							options={statusOptions}
							label="status"
							width="150px"
							height="35px"
							backgroundColor="#fff"
							selectHandler={selectStatusHandler}
						/>
						<InputWithLabel
							label="dateFrom"
							placeholder=""
							value={dateFrom || ''}
							onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateFrom(e.target.value)}
							type="date"
							height="34px"
							width="220px"
							border="1px solid #e0e0e0"
							boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
						/>
						<InputWithLabel
							label="dateTo"
							placeholder=""
							value={dateTo || ''}
							onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setDateTo(e.target.value)}
							type="date"
							height="34px"
							width="220px"
							border="1px solid #e0e0e0"
							boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
						/>
				    </>
				 	)}
				</div>
			)}
			{windowWidth <= 768 && (
				isLoadingHistory || (isFetchingHistory && isFilterClicked) ? (
					<>
          				{[...Array(10)].map((_, index) => (
            				<div key={index} style={{ marginTop: '16px' }}>
              					<Skeleton variant="rounded" height={140} />
            				</div>
          				))}
        			</>
				) : !transactions?.transactions ? (
					<div style={{ paddingBottom: '200px' }}>
						{t('components.noData')}
					</div>
			  	) : (
					<>
		    			{transactions?.transactions?.map((item, index) => (
							<BalanceTableCard 
								key={index} 
								item={item} 
						    />
					))}
					</>
				)
			)}
			{windowWidth > 768 && (
				isLoadingHistory || (isFetchingHistory && isFilterClicked) ? (
					<Skeleton variant="rounded" height={550} />
				) : (
				<TableWrapper>
					<Table>
						<thead>
							<tr>
								<th>{t('components.type')}</th>
								<th>ID</th>
								{getCookie('is_fiat') === 'true' && (<th>{t('components.description')}</th>)}
								<th>{t('components.adressHash')}</th>
								<th>{t('components.amount')}</th>
								<th>{t('components.commission')}</th>
								<th>{t('components.status')}</th>
								<th>{t('components.date')}</th>
							</tr>
						</thead>
						<tbody>
						{!transactions?.transactions ? (
							<tr>
						  		<td colSpan={9}>
									{t('components.noData')}
								</td>
							</tr>
				) : (
						transactions?.transactions?.map((item: ITransactionsResponseData, index: number) => {
							const dateCreate = new Date(item?.date_create).toLocaleString()
                            const dateComplete = item?.date_update ? new Date(item?.date_update).toLocaleString() : null
							return (
								<tr key={index}>
									<td>
										<div>{t(`components.${typeName[item.type]}`)}</div>
									</td>
									<td>
										<div>{item.tracker_id?.slice(0, 10) || '-'}</div>
									</td>
									{getCookie('is_fiat') === 'true' && (<td>
										{item?.transaction_description && (<div>{item?.transaction_description === 'Conversion' ? t('components.conversion') : item?.transaction_description}</div>)}
									</td>)}
									<td>
										{ item?.receiver || item?.hash ?
											<>
												<div style={{ display: 'flex', justifyContent: 'center', color: '#7154DC', fontWeight: '600', fontSize: '16px', margin: item.hash ? '0': '10px 0' }}>
													{item.receiver ?
                                                		<CopyableText text={item.receiver} isFull={true} color="#7154DC" />
                                                    		: null
                                                	}
												</div>
												<div
													onClick={() => {
														if (item?.hash && item?.hash !== 'internal') {
															window.open(`https://tronscan.org/#/transaction/${item?.hash}`, '_blank')
														}
													}}
													style={{
														display: 'flex',
														justifyContent: 'center',
														margin: item.receiver ? '0': '10px 0',
														cursor: item?.hash !== 'internal' ? 'pointer' : 'auto',
													}}
												>
													{item?.hash && item?.hash !== 'internal' && ( 
                                    					<>
                                        					<div style={{ color: '#636363' }}>
                                            					{item?.hash}
                                        					</div>
                                        					<OpenInNewIcon sx={{ width: '15px', height: '15px', color: '#636363' }} />
                                    					</>
                                    				)}
                                    				{item?.hash === 'internal' && ( 
                                        				<div style={{ color: '#636363' }}>
                                            				{item?.hash}
                                        				</div> 
                                    				)}
												</div>
											</>
										: <div></div>
										}
									</td>
									<td>
										<div>
											{
												item.type === 'IN' ?
													<div style={{ color: '#65B92F', fontWeight: '600', fontSize: '17px' }}>
														{item.amount ? `+ ${item.amount}` : null}
													</div> :
													<div style={{ color: '#D65A4A', fontWeight: '600', fontSize: '17px' }}>
														{item.amount ? `- ${item.amount}` : null}
													</div>
											}
											<div>
												<span>{item.token}</span>
											</div>
										</div>
									</td>
									<td>
										<div>{item?.transaction_commission === null ? '-' : item?.transaction_commission.toFixed(2) || '-'}</div>
									</td>
									<td>
										<StatusWrapper color={addStatusIcon(item.status)}>
											{t(`components.${findStatus(item.status)}`)}
										</StatusWrapper>
									</td>
									<td>
										<DateWrapper>{dateCreate}</DateWrapper>
                                        {(item?.status === 'ERROR' || item?.status === 'SUCCESS') && dateComplete && (
                                        	<DateWrapper color={addStatusIcon(item?.status)}>
                                                {dateComplete}
                                            </DateWrapper>
                                        )}
									</td>
								</tr>
							)})
						)}
						</tbody>
					</Table>
				</TableWrapper>))}
			<PaginationWrapper>
				<div style={{height: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
					<div style={{marginRight: '50px'}}>
						<Pagination
							page={page}
							offset={offset}
							limit={limit}
							onPageChange={handleOnChangePage}
							total={transactions?.transactions?.length}
							fetching={isFetchingHistory}
							loading={isLoadingHistory}
						/>
					</div>
				</div>
			</PaginationWrapper>
		</div>
	)
}

export default React.memo(Transactions)
