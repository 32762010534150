import React from 'react'
import { NavLink } from 'react-router-dom'
import {
    Menu,
    MenuItem,
    ListItemIcon,
    Divider,
    IconButton,
} from '@mui/material'
import { Logout, Menu as MenuIcon }from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { getCookie } from 'typescript-cookie'

import { header } from '../../libs/consts'


const MobileMenu = ({ HeaderLink, pathname, exit }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const {t} = useTranslation()
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
	    setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
	    setAnchorEl(null)
	}
  
	return (
	    <div>
	        <IconButton
		        onClick={handleClick}
        		size="small"
		        sx={{ p: 0, m: 0 }}
        		aria-controls={open ? 'account-menu' : undefined}
		        aria-haspopup="true"
        		aria-expanded={open ? 'true' : undefined}
            >
                <MenuIcon sx={{ width: 40, height: 40, color: '#7154DC' }}/>
            </IconButton>
		    <Menu
    		    id="basic-menu"
		        anchorEl={anchorEl}
		        open={open}
		        onClose={handleClose}
		        MenuListProps={{
			        'aria-labelledby': 'basic-button',
		        }}
		    >
			    {header.map((link) => (
				    <MenuItem key={link.link} onClick={handleClose}>
					    <HeaderLink isActive={pathname === link.link}>
    					    <NavLink to={link.link}>
	    					    {t(`components.${link.name}`)}
		    			    </NavLink>
			    		</ HeaderLink>
				    </MenuItem>
			    ))}
				{getCookie('is_fiat') === 'true' && (
					<MenuItem key="/conversions" onClick={handleClose}>
						<HeaderLink key="/conversions" isActive={pathname === '/conversions'}>
							<NavLink to="/conversions">
								{t('components.conversions')}
							</NavLink>
						</HeaderLink>
					</MenuItem>
				)}
				<MenuItem key="/settings" onClick={handleClose}>
					<HeaderLink key="/settings" isActive={pathname === '/settings'}>
						<NavLink to="/settings">
							{t('components.settings')}
						</NavLink>
					</HeaderLink>
				</MenuItem>
			    <Divider />
			    <MenuItem sx={{ color: '#7154DC' }} onClick={exit}>
                    <ListItemIcon sx={{ color: '#7154DC' }}>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t('components.logout')}
                </MenuItem>
		    </Menu>
	    </div>
	)
}

export default MobileMenu
