import React from 'react'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import Container from '../../components/container/container'
import Table from '../../components/table/Table'
import CustomSelect, { IOption } from '../../components/custom-select/CustomSelect'
import { addStatusIcon, findStatus } from '../../libs/utils/utils'
import { FilterIcon } from '../../assets/images/images'
import { useCreateAppealMutation } from '../../store/services/appeal-service'
import Pagination from '../../components/pagination/pagination'
import { ITransactionsResponseDataCrypto } from '../../types/types'
import useDebounce from '../../hooks/use-debounce'
import { useLazyRefreshQuery } from '../../store/services/base-service'
import { useFetchCryptoTransactionsMutation } from '../../store/services/crypto-transactions-service'
import CSVBalanceButton from '../../features/csv-balance-button/csv-balance-button'
import FilterCrypto from '../../features/filter/filterCrypto'
import CopyableText from '../../components/copyAbleText/copyAbleText'
import CryptoCard from '../../components/crypto-card/CryptoCard'

const SelectBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  margin: 24px 0px;
    
  @media screen and (max-width: 1015px) {
    gap: 28px;
  }

  @media screen and (max-width: 930px) {
    gap: 14px;
  }

  @media screen and (max-width: 350px) {
    flex-direction: column;
  }
`

const SelectsWrapper = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
`

const FilterWrapper = styled.div<{ isActive: boolean }>`
  > svg {
    fill: ${({ isActive }) => (isActive ? '#738297' : 'transparent')};
    transition: all 0.3s ease 0s;
  }
  cursor: pointer;
`

const Option = styled.div<{ active: boolean }>`
  width: 100%;
  padding: 10px 22px;
  font-size: 14px;
  line-height: 140%;
  cursor: pointer;
  text-align: center;
  background: ${({ active }) => (active ? '#fff' : '#fff')};
  color: ${({ active }) => (active ? '#7154DC' : '#989A9E')};

  @media screen and (max-width: 1170px) {
    padding: 10px 15px;
  }

  @media screen and (max-width: 1110px) {
    padding: 10px 8px;
  }
`
const StatusWrapper = styled.div<{ color: string }>`
  font-size: 14px;
  color: ${({ color }) => color};
`

const DateWrapper = styled.div<{ color?: string }>`
  font-size: 14px;
  white-space: nowrap;
  color: ${({ color }) => color || 'black'};
`

const TableWrapper = styled.div`
//   padding: 5px 5px 0px 5px;
  width: 100%;
  overflow-x: auto;
  box-shadow: 0px 4px 6px rgba(0,0,0,0.1);
`

const limitOptions = [
    { name: '10' },
    { name: '50' },
    { name: '100' },
    { name: '200' },
]

interface MainProps { }

const MainCrypto: React.FC<MainProps> = () => {
    const token = useAppSelector(state => state.currency.token)
    const {t} = useTranslation()

    const windowWidth = window.innerWidth
    const navigate = useNavigate()

    // const notifyErrorAppeal = () => toast.error(<FormattedMessage id="errorAppeal" />)
    // const notifySuccessAppeal = () => toast.success(<FormattedMessage id="successAppeal" />)

    const [receiver, setReceiver] = React.useState('')
    const [id, setId] = React.useState('')
    const [amount, setAmount] = React.useState('')
    const [transationId, setTransationId] = React.useState('')
    const [hash, setHash] = React.useState('')
    const [dateFrom, setDateFrom] = React.useState<string | undefined>(undefined)
    const [dateTo, setDateTo] = React.useState<string | undefined>(undefined)

    const debouncedReceiver = useDebounce(receiver, 500)
    const debouncedId = useDebounce(id, 500)
    const debouncedAmount = useDebounce(amount, 500)
    const debouncedTransationId = useDebounce(transationId, 500)
    const debouncedHash = useDebounce(hash, 500)
    const debouncedDateFrom = useDebounce(dateFrom, 500)
    const debouncedDateTo = useDebounce(dateTo, 500)

    const [refresh] = useLazyRefreshQuery()

    const [limit, setLimit] = React.useState<number>(10)
    const [type, setType] = React.useState<'IN' | 'OUT' | ''>('')
    const [status, setStatus] = React.useState<'ACCEPTED' | 'SUCCESS' | 'ERROR' | ''>('')
    const [offset, setOffset] = React.useState<number>(0)
    const [page, setPage] = React.useState<number>(1)

    const [isActiveFilter, setIsActiveFilter] = React.useState(false)
    // const [isVisiblePopupAppeal, setIsVisiblePopupAppeal] =
    //     React.useState<boolean>(false)
    // const [selectedId, setSelectedId] = React.useState<string>('')
    // const [appealMessage, setAppealMessage] = React.useState<string>('')

    // const [
    //     createAppeal,
    //     { isSuccess: isSuccessCreateAppeal, isError: isErrorCreateAppeal },
    // ] = useCreateAppealMutation()
    const [
        fetchCrypto,
        { data: transactions,
            isLoading: isLoadingHistory,
            isError: isErrorHistory,
        },
    ] = useFetchCryptoTransactionsMutation()

    React.useEffect(() => {
        if (isErrorHistory) {
            refresh(null)
        }
    }, [isErrorHistory])

    // React.useEffect(() => {
    //     if (isSuccessCreateAppeal) {
    //         notifySuccessAppeal()
    //         dispatch(setFiles64([]))
    //     }
    //     if (isErrorCreateAppeal) {
    //         notifyErrorAppeal()
    //         dispatch(setFiles64([]))
    //     }
    // }, [isSuccessCreateAppeal, isErrorCreateAppeal])

    const handleChangeActiveOption = React.useCallback(
        (value) => {
            setType(value)
            defaultPageHandler()
        },
        [],
    )

    const handleChangeActiveStatusOption = React.useCallback(
        (value) => {
            setStatus(value)
            defaultPageHandler()
        },
        [],
    )

    const handleChangeActiveFilter = React.useCallback(() => {
        setIsActiveFilter(!isActiveFilter)
    }, [isActiveFilter])

    const defaultPageHandler = React.useCallback(() => {
        setPage(1)
        setOffset(0)
        navigate('/')
    }, [])

    const handleOnChangePage = React.useCallback(
        (newPage: number) => {
            const newOffset = (newPage - 1) * limit
            setOffset(newOffset)
            setPage(newPage)
        },
        [limit],
    )

    React.useEffect(() => {
        defaultPageHandler()
    }, [receiver, id, status])

    React.useEffect(() => {
        fetchCrypto(
            {
                limit: limit,
                offset: offset,
                type: type,
                token: token?.token,
                tracker_id: String(debouncedId),
                hash: String(debouncedHash),
                receiver: String(debouncedReceiver),
                client_transaction_id: String(debouncedTransationId),
                amount: String(debouncedAmount),
                status: status,
                date_from: debouncedDateFrom ? new Date(debouncedDateFrom)?.toISOString() : '',
                date_to: debouncedDateTo ? new Date(debouncedDateTo)?.toISOString() : '',
            },
        )
    }, [token, page, type, status, limit, debouncedHash, debouncedAmount, debouncedId, debouncedReceiver, debouncedTransationId, debouncedDateFrom, debouncedDateTo ])

    const changeLimitHandler = React.useCallback(
        (limitOption: IOption) => {
            setLimit(+limitOption.name)
            setOffset(0)
            defaultPageHandler()
        },
        [page],
    )

    const inOutOptions = [
        { name: 'all', label: 'all', status: ''},
        { name: 'in', label: 'in', status: 'IN'},
        { name: 'out', label: 'out', status: 'OUT'},
    ]

    const selectedOptions = [
        { name: 'all', label: 'all', status: ''},
        { name: 'error', label: 'error', status: 'ERROR'},
        { name: 'success', label: 'success', status: 'SUCCESS'},
        { name: 'accepted', label: 'accepted', status: 'ACCEPTED'},
    ]

    return (
        <Container>
            <SelectBarContainer>
                {windowWidth <= 865 && (
                    <div style={{  display: 'flex', gap: '6px', flexDirection: windowWidth > 435 ? 'row' : 'column' }}>
                        {isLoadingHistory ? (
                            <>
                                <Skeleton variant="rounded" width={100} height={35} />
                                <Skeleton variant="rounded" width={135} height={35} />
                            </>
                            )  :  (
                            <>
                                <CustomSelect
                                    options={inOutOptions}
                                    width="100%"
                                    height="35px"
                                    backgroundColor="#fff"
                                    selectHandler={(e) => {
                                        const required = inOutOptions.find(op => op.name === e.name)
                                        if (required) {
                                            handleChangeActiveOption(required.status)
                                    }}}
                                    label=""
                                    selectedOption={{ name: inOutOptions.find(op => op.status === type)?.name || 'all' }}
                                /> 
                                <CustomSelect
                                    options={selectedOptions}
                                    width="135px"
                                    height="35px"
                                    backgroundColor="#fff"
                                    selectHandler={(e) => {
                                        const required = selectedOptions.find(op => op.name === e.name)
                                        if (required) {
                                            handleChangeActiveStatusOption(required.status)
                                    }}}
                                    label=""
                                    selectedOption={{ name: status.toLowerCase() || 'all' }}
                                />
                            </>
                        )}
                    </div>
                )}
                {windowWidth > 865 && ( isLoadingHistory ? (
                    <>
                        <Skeleton variant="rounded" width={windowWidth > 920 ? 200 : 102} height={41} />
                        <Skeleton variant="rounded" width={windowWidth > 920 ? 420 : 257} height={41} />
                    </>
              )  : (
                    <>
                        <SelectsWrapper>
                            <Option
                                active={type === 'IN'}
                                onClick={() => handleChangeActiveOption('IN')}
                            >
                                {t('components.in')}
                            </Option>
                            <Option
                                active={type === 'OUT'}
                                onClick={() => handleChangeActiveOption('OUT')}
                            >
                                {t('components.out')}
                            </Option>
                            <Option
                                active={type === ''}
                                onClick={() => {
                                    handleChangeActiveOption('')
                                }}
                            >
                                {t('components.all')}
                            </Option>
                        </SelectsWrapper>
                        <SelectsWrapper>
                            <Option
                                active={status === 'ERROR'}
                                onClick={() => handleChangeActiveStatusOption('ERROR')}
                            >
                                {t('components.error')}
                            </Option>
                            <Option
                                active={status === 'SUCCESS'}
                                onClick={() => handleChangeActiveStatusOption('SUCCESS')}
                            >
                                {t('components.success')}
                            </Option>
                            <Option
                                active={status === 'ACCEPTED'}
                                onClick={() => handleChangeActiveStatusOption('ACCEPTED')}
                            >
                                {t('components.accepted')}
                            </Option>
                            <Option
                                active={status === ''}
                                onClick={() => handleChangeActiveStatusOption('')}
                            >
                                {t('components.all')}
                            </Option>
                        </SelectsWrapper>
                    </>    
                ))}
                {isLoadingHistory ? <Skeleton variant="rounded" width={33} height={36} />  : (
                    <FilterWrapper
                        onClick={handleChangeActiveFilter}
                        isActive={isActiveFilter}
                    >
                        <FilterIcon /> 
                    </FilterWrapper>
                )}
                {isLoadingHistory ? <Skeleton variant="rounded" width={160} height={50} /> : <CSVBalanceButton />}
            </SelectBarContainer>
            {isActiveFilter && (
                <FilterCrypto
                    setReceiver={setReceiver}
                    setId={setId}
                    setAmount={setAmount}
                    setTransationId={setTransationId}
                    setHash={setHash}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                    receiver={receiver}
                    id={id}
                    amount={amount}
                    transationId={transationId}
                    hash={hash}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                />
            )}
            {windowWidth <= 768 &&  (
                isLoadingHistory ? (
                    <>
                        {[...Array(10)].map((_, index) => (
                            <div key={index} style={{ marginTop: '16px' }}>
                                <Skeleton variant="rounded" height={200} />
                            </div>
                        ))}
                    </>
            ) : !transactions?.transaction_list ? (
                <div style={{ paddingBottom: '200px' }}>
                    {t('components.noData')}
                </div>
            ) : <>
                    {transactions?.transaction_list?.map((item, index) => (
                      <CryptoCard 
                        key={index} 
                        item={item}
                      />
                    ))}
                </>
            )}
            {windowWidth > 768 && ( isLoadingHistory ? (
                    <Skeleton variant="rounded" height={800} />
                ) :  (
                    <TableWrapper>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        {t('components.status')}
                                    </th>
                                    <th>ID</th>
                                    <th>
                                        {t('components.clientTransactionId')}
                                    </th>
                                    {token?.type === 'CRYPTO' && ( 
                                    <th>
                                        Hash
                                    </th>
                                    )}
                                    <th>
                                        {t('components.requisites')}
                                    </th>
                                    <th>
                                        {t('components.amount')}
                                    </th>
                                    <th>
                                        {t('components.commission')}
                                    </th>
                                    <th>
                                        {t('components.credited')}
                                    </th>
                                    <th>
                                        {t('components.date')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {!transactions?.transaction_list ? (
                                <tr>
                                    <td colSpan={token?.type === 'CRYPTO' ? 10 : 9}>
                                        {t('components.noData')}
                                    </td>
                                </tr>
                            ) : (
                                transactions.transaction_list?.map(
                                    (item: ITransactionsResponseDataCrypto, index: number) => {
                                        const dateCreate = new Date(item?.date_create).toLocaleString()
                                        const dateComplete = item?.date_update ? new Date(item?.date_update).toLocaleString() : null
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <StatusWrapper color={addStatusIcon(item.status)}>
                                                        {t(`components.${findStatus(item.status)}`)}
                                                    </StatusWrapper>
                                                </td>
                                                <td>
                                                    <div>
                                                    {item.tracker_id ?
                                                        <CopyableText text={item?.tracker_id} />
                                                        : '-'
                                                    }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                                                    {item.client_transaction_id ?
                                                        <CopyableText text={item?.client_transaction_id} maxLength={30} />
                                                        : '-'
                                                    }
                                                    </div>
                                                </td>
                                               {token?.type === 'CRYPTO' && (
                                                <td>
                                                        <div style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                                                        {item.hash ?
                                                            <CopyableText text={item?.hash} />
                                                            : '-'
                                                        }
                                                        </div>
                                                </td>
                                                )}
                                                <td>
                                                <div style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                                                    {item.receiver ?
                                                        <CopyableText text={item?.receiver} maxLength={30} />
                                                        : '-'
                                                    }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {item.amount || '-'}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {item?.transaction_commission === null ? '-' : String(item?.transaction_commission).slice(0, 10) || '-'}
                                                    </div>
                                                </td>
                                                <td>
                                                    {item?.type === 'IN' ? <span style={{color: '#008000'}}>{`+${item.total}`}</span> : <span style={{color: '#ff0000'}}>{`-${item.total}`}</span>}
                                                </td>
                                                <td>
                                                <DateWrapper>{dateCreate}</DateWrapper>
                                                {(item?.status === 'ERROR' || item?.status === 'SUCCESS') && dateComplete && (
                                                    <DateWrapper color={addStatusIcon(item?.status)}>
                                                        {dateComplete}
                                                     </DateWrapper>
                                                )}
                                                </td>
                                            </tr>
                                        )
                                    },
                                )
                            )}
                        </tbody>
                    </Table>
               </TableWrapper>))}
                        <div style={{background: 'white', padding: '0 20px', marginBottom: '160px', width: '100%', boxShadow: '0px 4px 6px rgba(0,0,0,0.1)' }}>
                            <div style={{height: '50px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{marginRight: '50px'}}>
                                    <Pagination
                                        page={page}
                                        offset={offset}
                                        limit={limit}
                                        onPageChange={handleOnChangePage}
                                        total={transactions?.transaction_list?.length}
                                        loading={isLoadingHistory}
                                    />
                                </div>
                                <div>
                                    <CustomSelect
                                        options={limitOptions}
                                        width="70px"
                                        height="35px"
                                        label=""
                                        selectHandler={changeLimitHandler}
                                        loading={isLoadingHistory}
                                    />
                                </div>
                            </div>
                        </div>
        </Container>
    )
}

export default React.memo(MainCrypto)