import React from 'react'
import styled from 'styled-components/macro'


interface IWrapperProps {
	active: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
	z-index: 1;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease 0s;

	opacity: ${({ active }) => (active ? 1 : 0)};
	pointer-events: ${({ active }) => (active ? 'all' : 'none')};
`

interface IBodyProps {
	active: boolean;
	minWidth?: string;
	width?: string;
}
const Body = styled.div<IBodyProps>`
	z-index: 1;
	padding: 20px 20px 30px;
	width: ${({ width }) => (width || 'max-content')};
	min-width: ${({ minWidth }) => (minWidth || 'max-content')};
	background: #fff;
	box-shadow: 2px 2px 32px rgba(2, 6, 28, 0.16);
	border-radius: 24px;
	transition: all 0.3s ease 0s;
	display: flex;
	flex-direction: column;

	transform: ${({ active }) => (active ? 'scale(1)' : 'scale(.5)')};

	@media screen and (max-width: 480px) {
		min-width: 100%;
	}
`

interface IPopupProps {
	setIsVisible: (isVisible: boolean) => void;
	isVisible: boolean;
	minWidth?: string;
	width?: string;
}

const Popup: React.FC<React.PropsWithChildren<IPopupProps>> = ({
	children,
	setIsVisible,
	isVisible,
	minWidth,
	width,
}) => {
	const handleVisible = React.useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault()
		e.stopPropagation()
		setIsVisible(false)
	}, [])
	return (
		<Wrapper active={isVisible} onClick={handleVisible}>
			<Body minWidth={minWidth} width={width} active={isVisible} onClick={(e) => e.stopPropagation()}>
				{children}
			</Body>
		</Wrapper>
	)
}

export default React.memo(Popup)
