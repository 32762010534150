import React from 'react'

import { useAppSelector } from '../../hooks/redux'

import Main from './Main'
import MainCrypto from './MainCrypto'



interface MainProps { }


const MainRouter: React.FC<MainProps> = () => {
  const token = useAppSelector(state => state.currency.token)

  return (
    (token?.type === 'P2P' || token?.type === 'ACQUIRING' || token?.token === '')
      ? <Main selectedToken={token?.token} />
      : <MainCrypto />
  )

}




export default React.memo(MainRouter)
