import {
    createApi,
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'

import { getCookie } from 'typescript-cookie'

import { BASE_API_URL } from '../../libs/utils/api'

import { apiEndPoints } from '../../libs/consts'

import { secureQueryBuilder } from './_api'

const secureQuery = secureQueryBuilder(`${BASE_API_URL}`)

export const keysApi = createApi({
    baseQuery: secureQuery,
    reducerPath: 'keysApi',
    tagTypes: ['Key'],
    endpoints: (
        build: EndpointBuilder<
            BaseQueryFn<
                string | FetchArgs,
                {},
                FetchBaseQueryError,
                {},
                FetchBaseQueryMeta
            >,
            'Key',
            'keysApi'
        >,
    ) => ({
        getApiKeys: build.mutation<{ private_key: string, public_key: string }, {otp_code: string}>({
            query(args) {
                return {
                    url: apiEndPoints.GET_API_KEYS,
                    method: 'POST',
                    headers: {
                        authorization: `Bearer ${getCookie('access_token')}`,
                    },
                    body: {
                        ...args,
                    },
                }
            },
            transformResponse: (response: { private_key: string, public_key: string }) => response,
            invalidatesTags: () => [{ type: 'Key', id: 'ID' }],
        }),
        updateApiKeys: build.mutation<{ private_key: string, public_key: string }, {otp_code: string}>({
            query(args) {
                return {
                    url: apiEndPoints.UPDATE_API_KEYS,
                    method: 'PATCH',
                    headers: {
                        authorization: `Bearer ${getCookie('access_token')}`,
                    },
                    body: {
                        ...args,
                    },
                }
            },
            transformResponse: (response: { private_key: string, public_key: string }) => response,
            invalidatesTags: () => [{ type: 'Key', id: 'ID' }],
        }),
        getSessions: build.mutation<{ sessions: { ip: string, user_agent: string, sign_time: string, city: string, status: string }[] }, null>({
            query() {
                return {
                    url: apiEndPoints.GET_SESSIONS,
                    method: 'GET',
                    headers: {
                        authorization: `Bearer ${getCookie('access_token')}`,
                    },
                }
            },
            transformResponse: (response: { sessions: { ip: string, user_agent: string, sign_time: string, city: string, status: string }[] }) => response,
            invalidatesTags: () => [{ type: 'Key', id: 'ID' }],
        }),
        getSessionsInterval: build.query<{ sessions: { ip: string, user_agent: string, sign_time: string, city: string, status: string }[] }, null>({
            query() {
                return {
                    url: apiEndPoints.GET_SESSIONS,
                    method: 'GET',
                    headers: {
                        authorization: `Bearer ${getCookie('access_token')}`,
                    },
                }
            },
            transformResponse: (response: { sessions: { ip: string, user_agent: string, sign_time: string, city: string, status: string }[] }) => response,
        }),
        clearSessions: build.mutation({
            query() {
                return {
                    url: apiEndPoints.CLEAR_SESSIONS,
                    method: 'POST',
                    headers: {
                        authorization: `Bearer ${getCookie('access_token')}`,
                    },
                }
            },
            
            transformResponse: (response) => response,
            invalidatesTags: () => [{ type: 'Key', id: 'ID' }],
        }),
    }),
})

export const {
    useGetApiKeysMutation,
    useUpdateApiKeysMutation,
    useGetSessionsMutation,
    useGetSessionsIntervalQuery,
    useClearSessionsMutation,
} = keysApi
