import { PATH } from '../libs/consts'

import Dashboard from './dashboard/dashboard'
import Settings from './settings/Settings'
import Auth from './auth/auth'
import Withdraw from './withdraw/withdraw'
import MainRouter from './main/MainRouter'


export const pages = [
    { element: <Dashboard />, path: PATH.dashboard },
    { element: <MainRouter />, path: PATH.main },
    { element: <Settings />, path: PATH.settings },
    { element: <Auth />, path: PATH.auth },
    { element: <Withdraw />, path: PATH.inOut },
]
