import React from 'react'
import styled from 'styled-components/macro'

import Container from '../../components/container/container'
import BalanceCards from '../../features/BalanceCards/BalanceCards'






const Dashboard: React.FC = () => {
  return (
      <></>
        // <Container>
        //     <BalanceCards />
        // </Container>
    )
}




export default React.memo(Dashboard)
