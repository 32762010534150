import React, { useEffect, useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
 
interface CopyableTextProps { 
  text: string;
  isFull?: boolean;
  color?: string;
  isNotVisible?: boolean,
  maxLength?: number,
  isWrap?: boolean,
  truncatedLength?: number,
} 
 
const CopyableText: React.FC<CopyableTextProps> = ({ text, isFull, color, isNotVisible, maxLength, isWrap, truncatedLength }) => { 
  const [copied, setCopied] = useState(false)
  const {t} = useTranslation()
  const notifySuccess = () => toast.success(t('components.successCopy'))
  let truncatedText = ''
 
  const handleClickCopy = () => { 
    navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => { 
      setCopied(false)
    }, 1000) 
  }

  useEffect(() => {
    if (copied) {
      notifySuccess()
    }
  }, [copied])

  if (maxLength) {
    truncatedText = text?.length > maxLength ? `${text?.slice(0, 4)} ... ${text?.slice(text?.length-5, text?.length)}` : text
  } else if (truncatedLength) {
    truncatedText = text.slice(0, truncatedLength)
  } else if (!maxLength && !truncatedLength) {
    truncatedText = text
  }
  
 
  return ( 
    <div style={{ display: 'flex', alignItems: 'center'}}> 
      <span style={ isWrap ? { whiteSpace: 'normal', wordBreak: 'break-all' } : { whiteSpace: 'nowrap' }}>
        {isNotVisible ? null : isFull ? text : truncatedText}
      </span>
      <ContentCopyIcon 
        onClick={handleClickCopy} 
        style={{ marginLeft: '5px', cursor: 'pointer', color: copied ? 'green' : color || 'black' }} 
        fontSize="small" 
      /> 
    </div> 
  )
}

export default CopyableText
