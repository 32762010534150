import React from 'react'
import QRCode from 'react-qr-code'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Cookies } from 'typescript-cookie'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'
import LanguageIcon from '@mui/icons-material/Language';
import { Box } from '@mui/material'

import Button from '../../components/button/button'
import UIInput from '../../components/input/inputUI'
import { useAppDispatch } from '../../hooks/redux'
import { useKeyPress } from '../../hooks/use-key-press'
import { AuthorizationStatus, PATH } from '../../libs/consts'
import { useAuthorizationMutation, useSignupMutation } from '../../store/services/base-service'
import { setAuthorizationStatus } from '../../store/slices/base-slice'
import CopyableText from '../../components/copyAbleText/copyAbleText'


const QRBlock = styled.div`
  background: #141625;
	border-radius: 25px;
	border: 1px solid #333D4E;
  padding: 16px;
`

const Auth: React.FC = () => {
  const [login, setLogin] = React.useState(true)
  const [otpCode, setOtpCode] = React.useState('')  
  const {t, i18n} = useTranslation()

  return (
    <div className="auth__wrapper">
      <div className="auth__header">
        <div style={{display: 'flex', flexDirection: 'row', height: '30px'}}>
        <Box
								onClick={() => i18n.changeLanguage(i18next.language === 'en' ? 'ru' : 'en')}
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-around',
									cursor: 'pointer',
									width: '40px',
									height: '30px',
									'@media (max-width: 828px)': {
										width: '40px',
									},
									'@media (max-width: 768px)': {
										marginLeft: '0',
									},
								}}
							>
								<LanguageIcon sx={{height: '30px', width: '30px', color: '#989A9E'}}
								/>                   
							</Box>
          <Button
            variant="secondary"
            onClick={() => setLogin(!login)}
          >
              {login ? t('components.signUp') : t('components.signIn')}
          </Button>
        </div>
      </div>
      <div className="auth__content-wrapper">
        <div className="auth__content">
          {login
            ? <Login otpCode={otpCode} />
            : <Registration setOtpCode={setOtpCode} setLogin={setLogin} />
          }
        </div>
      </div>
    </div>
  )
}

const Login: React.FC<{ otpCode: string }> = React.memo(({ otpCode }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {t} = useTranslation()
  const urlOtpCode = new URL(otpCode || 'http://www.example.com')
  const secretParam = urlOtpCode.searchParams.get('secret')

  const notifyError = () => toast.error(t('components.error'))
  const notifySuccess = () => toast.success(t('components.successSignIn'))

  const [auth, setAuth] = React.useState<{
    email: string;
    password: string;
    otp_code: string;
  }>({
    email: '',
    password: '',
    otp_code: '',
  })

  const disabled = !auth.email || !auth.password || !auth.otp_code

  const handleChangeAuthValue = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
      if (name === 'email') {
        setAuth({ ...auth, email: e.target.value })
      }
      if (name === 'password') {
        setAuth({ ...auth, password: e.target.value })
      }
      if (name === 'otp_code') {
        setAuth({ ...auth, otp_code: e.target.value })
      }
    }, [auth])

  const [authorization, { isSuccess: isSuccessAuth, isError: isErrorAuth }] = useAuthorizationMutation()

  const handleAuth = React.useCallback(() => {
    authorization({ email: auth.email.trim(), password: auth.password.trim(), otp_code: auth.otp_code.replace(/\s+/g, '') })
    // navigate(PATH.main)
  }, [auth.email, auth.password, auth.otp_code])

  React.useEffect(() => {
    if (isSuccessAuth) {
      dispatch(setAuthorizationStatus(AuthorizationStatus.AUTH))
      Cookies.set('isAuthlkxp', AuthorizationStatus.AUTH)
      Cookies.set('usernamelkxp', auth.email)
      notifySuccess()
    }
    if (isErrorAuth) {
      notifyError()
    }
  }, [isSuccessAuth, isErrorAuth])

  const isEnterPressed = useKeyPress('Enter')

  React.useEffect(() => {
    if (isEnterPressed && auth.email && auth.password && auth.otp_code) {
      handleAuth()
    }
  }, [isEnterPressed])

  return (
    <React.Fragment>
      <UIInput
        value={auth.email}
        onChangeHandler={(e) => handleChangeAuthValue(e, 'email')}
        label="login"
      />
      <UIInput
        value={auth.password}
        onChangeHandler={(e) => handleChangeAuthValue(e, 'password')}
        label="password"
        type="password"
      />
      <UIInput
        value={auth.otp_code}
        onChangeHandler={(e) => handleChangeAuthValue(e, 'otp_code')}
        label="twofa"
        type="text"
      />
      {
        otpCode?.length > 0 ?
        <QRBlock>
          <div style={{ display:'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <QRCode
              size={400}
              // className="qrStyle"
              style={{ minWidth: '126px', maxWidth: '45%', height: 'auto', border: '5px solid #fff', marginRight: '10px' }}
              value={otpCode}
              viewBox={'0 0 100 100'}
            />
            <p style={{width: '45%', color: '#fff'}}>
              {t('components.save2fa')}
            </p>
          </div>
          <div>
            <p style={{color: '#fff', fontSize: '13px', wordBreak: 'break-all'}}>
              <CopyableText text={secretParam || ''} isFull={true} color="#fff" />
            </p>
          </div>
        </QRBlock> : null
      }
      <div className="auth__btn-wrapper">
        <Button variant="primary" onClick={handleAuth} disabled={disabled}>
          {t('components.signIn')}
        </Button>
      </div>
    </React.Fragment>
  )
})

const Registration: React.FC<{ setLogin: (value: boolean) => void, setOtpCode: (value: string) => void }> = React.memo(({ setLogin, setOtpCode }) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [passwordError, setPasswordError] = React.useState<string | null>(null)
  const [passwordConfirmationError, setPasswordConfirmationError] = React.useState<string | null>(null)

  const notifyError = () => toast.error(t('components.error'))
  const notifySuccess = () => toast.success(t('components.successSignUp'))

  const [reg, setRegistration] = React.useState<{ login: string, password: string, repeat_password: string, invite_code: string }>({
    login: '',
    password: '',
    repeat_password: '',
    invite_code: '',
  })

  const disabled = !reg.login || !reg.password || !reg.repeat_password || !reg.invite_code || passwordError || passwordConfirmationError
  
  const isPasswordError = (password: string): { error: string | null } => {
    const hasSpaces = /\s/
    if (hasSpaces.test(password)) {
      return { error: 'notSpaces' }
    }

    if (password.length === 0) {
      return { error: null }
    }
  
    if (password.length < 6) {
      return { error: 'passwordLength' }
    }

    const hasUppercase = /[A-Z]/
    const hasLowercase = /[a-z]/
    const hasDigit = /\d/
    const hasSpecial = /[!@#$%^&*()_+\-=[\]{};':"|,.<>/?]+/

    if (!(hasUppercase.test(password) && hasLowercase.test(password) && hasDigit.test(password) && hasSpecial.test(password))) {
        return { error: 'passwordMustContain' }
    }
    
    return { error: null }
  }

  const isPasswordConfirmationError = (passwordConfirmation: string) : string | null => {
    if (passwordConfirmation.length === 0) {
      return null
    }
    if (passwordConfirmation !== reg.password) {
      return 'notMatch'
    }
    return null
  }

  const handleChangeAuthValue = React.useCallback((e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (name === 'login') {
      setRegistration({ ...reg, login: e.target.value })
    }
    if (name === 'password') {
      const newPassword = e.target.value
      setRegistration({ ...reg, password: newPassword })
      setPasswordError(null)
      const { error } = isPasswordError(newPassword)
      setPasswordError(error)
    }
    if (name === 'repeat password') {
      const newPasswordConfirmation = e.target.value
      setRegistration({ ...reg, repeat_password: newPasswordConfirmation })
      setPasswordConfirmationError(null)
      setPasswordConfirmationError(isPasswordConfirmationError(newPasswordConfirmation))
    }
    if (name === 'invite code') {
      setRegistration({ ...reg, invite_code: e.target.value })
    }
  }, [reg])

  const [signup, { data: signUpData, isSuccess: isSuccessSignup, isError: isErrorSignup }] = useSignupMutation()

  const handleRegistration = React.useCallback(() => {
    signup({ email: reg.login.trim(), password: reg.password, repeated_password: reg.repeat_password, invite_code: reg.invite_code.trim(), service_name: 'expay' })
    navigate(PATH.auth)
  }, [reg])

  React.useEffect(() => {
    if (isSuccessSignup) {
      notifySuccess()
      setLogin(true)
      if(signUpData?.otp_url) {
        setOtpCode(signUpData?.otp_url)
      }
    }
    if (isErrorSignup) {
      notifyError()
      setRegistration({
        login: '',
        password: '',
        repeat_password: '',
        invite_code: '',
      })
    }
  }, [isSuccessSignup, isErrorSignup])

  return (
    <React.Fragment>
      <UIInput
        value={reg.login}
        onChangeHandler={e => handleChangeAuthValue(e, 'login')}
        label="login"
      />
      <UIInput
        value={reg.password}
        onChangeHandler={e => handleChangeAuthValue(e, 'password')}
        error={passwordError || ''}
        label="password"
        type="password"
      />
      <UIInput
        value={reg.repeat_password}
        onChangeHandler={e => handleChangeAuthValue(e, 'repeat password')}
        label="repeatedPass"
        type="password"
        error={passwordConfirmationError || ''}
      />
      <UIInput
        value={reg.invite_code}
        onChangeHandler={e => handleChangeAuthValue(e, 'invite code')}
        label="invite"
      />
      <div className="auth__btn-wrapper">
        <Button variant="primary" onClick={handleRegistration} disabled={disabled}>{t('components.signUp')}</Button>
      </div>
    </React.Fragment>
  )
})


export default React.memo(Auth)
