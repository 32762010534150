import React from 'react'

import Container from '../../components/container/container'
import InOut from '../../features/in-out/in-out'
import TransactionsTable from '../../features/transactions-table/TransactionsTable'

const Withdraw: React.FC = () => {
	return (
		<Container>
			<InOut />
			<TransactionsTable />
		</Container>
	)
}

export default React.memo(Withdraw)
