import { configureStore } from '@reduxjs/toolkit'

import { courseSlice } from './slices/course-slice'
import { historySlice } from './slices/history-slice'
import { baseSlice } from './slices/base-slice'
import { reportsSlice } from './slices/reports-slice'

import { baseApi } from './services/base-service'
import { ordersApi } from './services/orders-service'
import { appealApi } from './services/appeal-service'
import { balanceApi } from './services/balance-service'
import { tokensApi, tokensCryptoApi } from './services/tokens-service'
import { courseApi } from './services/course-service'
import { cashflowApi } from './services/cashflow-service'
import { transactionsApi } from './services/transactions-service'

import currency from './slices/currency-slice'
import { imageApi } from './services/image-service'
import { keysApi } from './services/apikeys-service'
import { downloadApi } from './services/download-service'
import { cryptoTransactionsApi } from './services/crypto-transactions-service'
import { conversionsApi } from './services/conversions-service'

const store = configureStore({
    reducer: {
        currency: currency,
        base: baseSlice.reducer,
        history: historySlice.reducer,
        course: courseSlice.reducer,
        reports: reportsSlice.reducer,

        [appealApi.reducerPath]: appealApi.reducer,
        [baseApi.reducerPath]: baseApi.reducer,
        [ordersApi.reducerPath]: ordersApi.reducer,
        [tokensApi.reducerPath]: tokensApi.reducer,
        [tokensCryptoApi.reducerPath]: tokensCryptoApi.reducer,
        [balanceApi.reducerPath]: balanceApi.reducer,
        [courseApi.reducerPath]: courseApi.reducer,
        [cashflowApi.reducerPath]: cashflowApi.reducer,
        [transactionsApi.reducerPath]: transactionsApi.reducer,
        [cryptoTransactionsApi.reducerPath]: cryptoTransactionsApi.reducer,
        [imageApi.reducerPath]: imageApi.reducer,
        [keysApi.reducerPath]: keysApi.reducer,
        [downloadApi.reducerPath]: downloadApi.reducer,
        [conversionsApi.reducerPath]: conversionsApi.reducer,
    },
    middleware: (gDM) => gDM().concat([
        cryptoTransactionsApi.middleware,
        appealApi.middleware,
        baseApi.middleware,
        ordersApi.middleware,
        tokensApi.middleware,
        tokensCryptoApi.middleware,
        balanceApi.middleware,
        courseApi.middleware,
        cashflowApi.middleware,
        transactionsApi.middleware,
        imageApi.middleware,
        keysApi.middleware,
        conversionsApi.middleware,
    ]),
})
export default store

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
